const statusPageData = Object.freeze([
    {
        title: 'TeleTracking IQ® PLATFORM',
        statusPageUrls: [
            { title: 'US', url: 'https://us.status.teletracking.com' },
            { title: 'EU', url: 'https://eu.status.teletracking.com' },
        ],
    },
    {
        title: 'MANAGED SERVICE ENVIRONMENTS',
        statusPageUrls: [
            { title: 'MS6192-1', url: 'https://ms6192.status.teletracking.com' },
            { title: 'MS7011-1', url: 'https://ms7011.status.teletracking.com' },
            { title: 'MS1088-1', url: 'https://ms1088.status.teletracking.com' },
            { title: 'MS6195-1', url: 'https://ms6195.status.teletracking.com' },
            { title: 'MS16851-1', url: 'https://ms16851.status.teletracking.com' },
            { title: 'MS0703-1', url: 'https://ms0703.status.teletracking.com' },
            { title: 'MS25297-1', url: 'https://ms25297.status.teletracking.com', component: 'Pod 1' },
            { title: 'MS25297-2', url: 'https://ms25297.status.teletracking.com', component: 'Pod 2' },
            { title: 'MS25297-3', url: 'https://ms25297.status.teletracking.com', component: 'Pod 3' },
        ],
    },
]);

export default statusPageData;
