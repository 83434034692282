import React from 'react';
import './main.scss';
import logo from '@mosaic/mosaic-ui/assets/images/tele_logo_secondary.svg';
import StatuspageCardContainer from './components/StatuspageCardContainer';

import statusPageData from './statusPageData';

const App = () => (
    <div className="App">
        <div className="status-page-header">
            <div className="status-page-header__logo">
                <img src={logo} alt="TeleTracking Logo" />
            </div>
            <div className="status-page-header__header-title">
                OVERALL | REAL-TIME STATUS
            </div>
        </div>
        <div className="status-page-welcome status-page-welcome--fixed-width">
            <div className="status-page-welcome__welcome-message">
                Welcome to the TeleTracking Status Page. This
                status
                page
                provides a summary
                view
                into each
                environment. Click DETAILS for information on each status.
            </div>
            <div className="status-page-welcome__contact-link">
                <a href="mailto:teleops@teletracking.com">CONTACT</a>
            </div>
        </div>
        <div className="status-page-card-container__container">
            { statusPageData.map((data) => (
                <StatuspageCardContainer
                    key={data.title}
                    title={data.title}
                    data={data.statusPageUrls}
                />
            ))}
        </div>
        <div className="status-page-footer">
            ©
            {(new Date().getFullYear())}
            {' '}
            TeleTracking Technologies, Inc. All Rights Reserved
        </div>
    </div>
);

export default App;
