import React from 'react';
import PropTypes from 'prop-types';
import '../main.scss';

import StatuspageCard from './StatuspageCard';

const StatuspageCardContainer = ({ title, data }) => (
    <div className="status-page-card-container status-page-card-container--fixed-width">
        <div className="status-page-card-container__title">
            {title}
        </div>
        <div className="status-page-card-container__card-container">
            {data.map((cardData) => (
                <div key={cardData.title} className="card-container__card__item">
                    <StatuspageCard
                        title={cardData.title}
                        source={cardData.url}
                        component={cardData.component}
                    />
                </div>
            ))}
        </div>
    </div>
);

StatuspageCardContainer.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        component: PropTypes.string,
    })).isRequired,
};

export default StatuspageCardContainer;
