import React from 'react';
import PropTypes from 'prop-types';
import '../main.scss';
import { FaQuestionCircle } from 'react-icons/fa';

import descriptionToStatusTextLookup from './descriptionToStatusTextLookup';
import descriptionToIconLookup from './descriptionToIconLookup';

const convertToSlug = (text) => text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

const StatuspageStatusIndicator = ({ statusDescription }) => {
    const statusText = descriptionToStatusTextLookup[statusDescription] || 'Unknown Status';
    const StatusIcon = descriptionToIconLookup[statusDescription] || FaQuestionCircle;
    return (
        <div className={`status-card-status-indicator ${convertToSlug(statusText)}`}>
            {(statusText === 'Loading')
                ? (
                    <div className="status-card-status-indicator__circle-spinner">
                        <div />
                    </div>
                )
                : (
                    <div className="status-card-status-indicator__content">
                        <StatusIcon className="status-card-status-indicator__status-icon" />
                        <div>{statusText}</div>
                    </div>
                )}
        </div>
    );
};

StatuspageStatusIndicator.propTypes = {
    statusDescription: PropTypes.string.isRequired,
};

export default StatuspageStatusIndicator;
