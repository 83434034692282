import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../main.scss';
import fetchSummary from '../util/fetchSummary';
import formatDate from '../util/formatDate';
import STATUS_REFRESH_TIMER_IN_MS from '../constants';
import getComponentDescriptionFromSummary from './getComponentDescriptionFromSummary';

import StatuspageStatusIndicator from './StatuspageStatusIndicator';

const StatuspageCard = ({ title, source, component }) => {
    const [description, updateDescription] = React.useState('Loading');
    const [lastUpdateTime, updateLastUpdateTime] = React.useState('');

    useEffect(() => {
        const getSummary = async () => {

            const result = await fetchSummary(source);
            if (component) {
                updateDescription(getComponentDescriptionFromSummary(result.components, component));
            } else {
                updateDescription(result.status.description);
            }
            updateLastUpdateTime(formatDate(title, result.page.updated_at));
        };
        getSummary();

        const interval = setInterval(async () => getSummary(), STATUS_REFRESH_TIMER_IN_MS);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="status-page-card">
            <div className="status-page-card__title">
                {title}
            </div>
            <StatuspageStatusIndicator statusDescription={description} />
            <div className="status-page-card__footer">
                <div>
                    Updated
                    {' '}
                    {lastUpdateTime}
                </div>
                <div>
                    <a href={source}>DETAILS</a>
                </div>
            </div>
        </div>
    );
};

StatuspageCard.propTypes = {
    title: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    component: PropTypes.string,
};

StatuspageCard.defaultProps = {
    component: '',
};

export default StatuspageCard;
