import {
    FaCheck, FaMinusSquare, FaExclamationTriangle, FaWrench, FaExclamationCircle,
} from 'react-icons/fa';

const descriptionToIconLookup = Object.freeze({
    'All Systems Operational': FaCheck,
    operational: FaCheck,
    'Partially Degraded Service': FaMinusSquare,
    degraded_performance: FaMinusSquare,
    'Partial System Outage': FaExclamationTriangle,
    partial_outage: FaExclamationTriangle,
    Maintenance: FaWrench,
    under_maintenance: FaWrench,
    'Major Service Outage': FaExclamationCircle,
    major_outage: FaExclamationCircle,
});

export default descriptionToIconLookup;
